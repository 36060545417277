import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import LoremIpsum from '../LoremIpsum'
import HTMLPages from '../HTMLPages'

const Body = ({ filename, section }) => {
  const data = useStaticQuery(graphql`
    {
      allFile {
        edges {
          node {
            name
          }
        }
      }
    }
  `)
  
  const sourcedFile = _.find(data.allFile.edges, file => file.node.name === filename)
  const innerComponent = (filename => {
    switch(filename) {
      default:         
        return sourcedFile ? <HTMLPages filename={filename} section={section} /> : <LoremIpsum />
    }
  })(filename)

  return (
    <BodyContainer>
      {innerComponent}
    </BodyContainer>
  )
}

const BodyContainer = styled.div`
  padding-top: 4rem;    /* Navbar height is 4rem (leading-16) */
`

export default Body
