import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import Navbar from '../components/Navbar'
import Body from '../components/Body'
import titlify from '../utils/titlify'

const IndexTemplate = ({ pageContext }) => (
  <IndexTemplateContainer>
    <Navbar />
    <Body filename={pageContext.filename} section={pageContext.section} />
    <Helmet>
      <title>
        { (pageContext.filename)
            ? titlify(pageContext.filename)
            : 'tw'
        }
      </title>
    </Helmet>
  </IndexTemplateContainer>
)

const IndexTemplateContainer = styled.div``

export default IndexTemplate