import React, { useState } from 'react'
import styled from 'styled-components'
//import { throttle } from 'lodash'
//import tw from 'tailwind.macro'

const Navbar = () => {
  // const desktopBreak = 767
  // const [showDesktopLinks, setShowDesktopLinks] = useState( window.innerWidth > desktopBreak )
  
  // useEffect(() => {
  //   window.addEventListener('resize', throttle(() => {
  //     window.innerWidth > desktopBreak ? setShowDesktopLinks(true) : setShowDesktopLinks(false)
  //   }, 5))
  //   return () => window.removeEventListener('resize', throttle(() => {
  //     window.innerWidth > desktopBreak ? setShowDesktopLinks(true) : setShowDesktopLinks(false)
  //   }, 5))
  // })

  const [showMobileLinks, setShowMobileLinks] = useState(false)
  const toggleMobileMenu = () => { 
    setShowMobileLinks(!showMobileLinks)
  }

  return (
    <NavbarContainer>
      <nav id="Navbar" className="flex items-center justify-between flex-wrap bg-white px-8 md:px-24 pt-0 shadow-lg fixed w-screen z-10 transition-max-height duration-200 ease-in-out overflow-hidden">
        <div className="Navbar-Logo flex items-center flex-shrink-0 text-black mr-12 my-0">
          <span className="font-brand-logo text-48 md:text-64 tracking-tight leading-16">
            TW
          </span>
        </div>
        <div className="Navbar-Mobile-Menu block md:hidden">
          <button onClick={toggleMobileMenu} className="flex items-center px-3 py-2 border rounded text-gray border-gray hover:text-black hover:border-black">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>
              Menu
            </title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        { showMobileLinks && (
          <div className="Navbar-Links w-full block flex-grow md:flex md:items-center md:w-auto">
            <div className="font-brand-text text-sm md:flex-grow pb-6 md:p-0">
              <a href="#responsive-header" className="block mt-4 md:inline-block md:mt-0 text-gray-600 hover:text-black mr-4">
                Docs
              </a>
              <a href="#responsive-header" className="block mt-4 md:inline-block md:mt-0 text-gray-600 hover:text-black mr-4">
                Examples
              </a>
              <a href="#responsive-header" className="block mt-4 md:inline-block md:mt-0 text-gray-600 hover:text-black">
                Blog
              </a>
            </div>
          </div>
        )}
      </nav>
    </NavbarContainer>
  )
}

const NavbarContainer = styled.div`
  z-index: 10;
`
/*const NavbarContainer = styled.div.attrs({
  className: "flex items-center justify-between flex-wrap bg-teal-500 p-6"
})``*/
/*const NavbarContainer = styled.div`
  ${tw`font-mono text-xl`};
  background-color: #eeeeee;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`*/

export default Navbar
