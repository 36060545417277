import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import cheerio from 'cheerio'
import _ from 'lodash'

const HTMLPages = ({ filename, section }) => {
  const data = useStaticQuery(graphql`
    {
      allFile {
        edges {
          node {
            name
            fields {
              fileContents
            }
          }
        }
        totalCount
      }
    }
  `)
  
  const sourcedFile = _.find(data.allFile.edges, file => file.node.name === filename)
  const $ = cheerio.load(sourcedFile.node.fields.fileContents)
  // https://github.com/cheeriojs/cheerio/issues/748
  // TODO: Extend Cheerio properly, centrally
  $.fn.wrapAll = function(wrapper) {
    if (this.length < 1) {
      return this;
    }
    if (this.length < 2 && this.wrap) { // wrap not defined in npm version,
        return this.wrap(wrapper);      // and git version fails testing.
    }
    var elems = this;
    var section = $(wrapper);
    var marker = $('<div>');
    marker = marker.insertBefore(elems.first()); // in jQuery marker would remain current
    elems.each(function(k, v) {                  // in Cheerio, we update with the output.
        section.append($(v));
    });
    section.insertBefore(marker); 
    marker.remove();
    return section;                 // This is what jQuery would return, IIRC.
  }

  // Remove unnecessary attributes
  $("*").removeAttr("data-line")        // 1.
  $(".code-line").removeAttr("class")   // 2.

  // Remove default anchor links
  $('a').filter('[for]').remove()

  // Update TOC links to point to pages
  const tocId = '#table-of-contents'
  if ($(tocId).length) {
    // Wrap the toc section
    $(tocId).nextUntil('h2').addBack(tocId).wrapAll('<div id="toc-wrapped">')
    $('#toc-wrapped').prepend($('h1'))
    
    // Modify all links in toc
    $('#toc-wrapped').find('a').each((i, el) => {
      let thisHref = String($(el).attr('href'))
      let newHref = thisHref.replace('#', '/' + filename + '/')
      $(el).attr('href', newHref)
    })
  }
  
  const htmlToLoad = (section => {
    switch(section) {
      case '/':
        if ($(tocId).length) {
          return $('#toc-wrapped').html()
        } else {
          return $('body').html()
        }
      case '__all':
        return $('body').html()
      default:
        const sectionId = '#' + section
        const thisLvl = parseInt($(sectionId).get(0).tagName.slice(1))
        let nextIndex = Number.MAX_SAFE_INTEGER
        let nextTag = null
        for (let searchLvl = thisLvl; searchLvl >= 2; searchLvl--) {
          let searchForTag = 'h' + searchLvl
          let nextHeaderIndex = parseInt($(sectionId).nextAll(searchForTag).first().index())
          if (nextHeaderIndex === -1) { continue }
          if (nextHeaderIndex < nextIndex) {
            nextIndex = nextHeaderIndex
            nextTag = searchForTag
          }
        }
        if (nextIndex < Number.MAX_SAFE_INTEGER) {
          $(sectionId).nextUntil(nextTag).addBack(sectionId).wrapAll('<div id="all-wrapped">')
          return $('#all-wrapped').html()
        } else {
          $(sectionId).nextAll().addBack(sectionId).wrapAll('<div id="all-wrapped">')
          return $('#all-wrapped').html()
        }
    }
  })(section)

  return (
    <HTMLPagesContainer>
      <div dangerouslySetInnerHTML={{ __html: htmlToLoad }} />
    </HTMLPagesContainer>
  )
}

const HTMLPagesContainer = styled.div`
  /* base styles */
  font-size: 14px;
  line-height: 22px;
  margin: auto;
  padding: 0 26px;
  max-width: 980px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  p,
  ol,
  ul {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    padding-bottom: 0.3em;
    line-height: 1.2;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  h2 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    padding-top: 0.5em;
  }
  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-top: 0.39em;
  }
  h4 {
    font-weight: bold;
    font-size: 1em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    padding-top: 0.3em;
  }
  h5 {
    font-weight: bold;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    padding-top: 0.28em;
  }
  p,
  ul,
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  ul,
  ol {
    padding-inline-start: 20px;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  ul ul {
    list-style-type: circle;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  ul ul ul {
    list-style-type: square;
  }
  li {
    display: list-item;
    min-width: 0;
  }
  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    margin: 0 7px 0 5px;
    padding: 0 16px 0 10px;
    border-left-width: 5px;
    border-left-style: solid;
    border-color: black;
  }
  pre {
    display: block;
    margin: 1em 0px;
    padding: 16px;
    background-color: rgba(220, 220, 220, 0.4);
    border-radius: 3px;
    overflow: auto;
    white-space: pre;
  }
  code {
    tab-size: 4;
  }
  a:hover {
    text-decoration: underline;
  }

  /* Disabling anchor widget for now
  .octicon {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
  }
  .anchor {
    float: left;
    margin-left: -20px;
    padding-right: 4px;
  }
  .anchor:focus {
    outline: none;
  }
  h1 .octicon-link,
  h2 .octicon-link,
  h3 .octicon-link,
  h4 .octicon-link,
  h5 .octicon-link,
  h6 .octicon-link {
    color: #1b1f23;
    vertical-align: middle;
    visibility: hidden;
  }
  h1:hover .anchor,
  h2:hover .anchor,
  h3:hover .anchor,
  h4:hover .anchor,
  h5:hover .anchor,
  h6:hover .anchor {
    text-decoration: none;
  }
  h1:hover .anchor .octicon-link,
  h2:hover .anchor .octicon-link,
  h3:hover .anchor .octicon-link,
  h4:hover .anchor .octicon-link,
  h5:hover .anchor .octicon-link,
  h6:hover .anchor .octicon-link {
    visibility: visible;
  }
  h1:hover .anchor .octicon-link:before,
  h2:hover .anchor .octicon-link:before,
  h3:hover .anchor .octicon-link:before,
  h4:hover .anchor .octicon-link:before,
  h5:hover .anchor .octicon-link:before,
  h6:hover .anchor .octicon-link:before {
    width: 16px;
    height: 16px;
    content: " ";
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' width='16' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'%3E%3C/path%3E%3C/svg%3E");
  }
  */

  /* checkbox will be invisible; its label will be used to check/uncheck it */
  input[type='checkbox'] {
    display: none;
  }
  
  /* style default label */
  .lbl-toggle {
    cursor: pointer;
    transition: all 0.25s ease-out;
  }
  .lbl-toggle:hover {
  }
  
  /* triangle shape for expand/collapse */
  .lbl-toggle::before {
    content: ' ';
    display: inline-block;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;

    vertical-align: middle;
    margin-right: .7rem;   /* was .7 */
    transform: translateY(-2px);

    transition: transform .2s ease-out;
  }

  /* start collapsible-content completely hidden */
  .collapsible-content {
    max-height: 0px;
    overflow: hidden;

    transition: max-height .25s ease-in-out;
  }

  /* show collapsible-content when checkbox is checked */
  .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 100%;  /* using % loses transitions apparently */
    overflow: visible; /* fixes truncation issues when text is visible */
  } 

  /* rotate triangle when expanding/collapsing */
  .toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
  }
  .toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  /* tweak header appearance (incomplete) */
  .toggle:checked + .lbl-toggle > h5,
  .toggle:checked + .lbl-toggle > h4,
  .toggle:checked + .lbl-toggle > h3,
  .toggle:checked + .lbl-toggle > h2 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 1em;
  }
  .toggle:not(:checked) + .lbl-toggle > h5,
  .toggle:not(:checked) + .lbl-toggle > h4,
  .toggle:not(:checked) + .lbl-toggle > h3,
  .toggle:not(:checked) + .lbl-toggle > h2 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 1em;
  }
  .toggle + .lbl-toggle > h5 {  padding-inline-start: 120px;  }
  .toggle + .lbl-toggle > h4 {  padding-inline-start: 80px;   }
  .toggle + .lbl-toggle > h3 {  padding-inline-start: 40px;   }
  .toggle + .lbl-toggle > h2 {  padding-inline-start: 0px;    }

  /* Unwrap table of contents */
  #toc-wrapped {  white-space: nowrap;  }

`

export default HTMLPages

